import React from 'react';

import HelpButton from './components/HelpButton';
import EmailCustomerSupport from './components/EmailCustomerSupport';

import './CustomerSupport.scss';

const CustomerSupport = () => (
	<div style={{ display: 'inline-block' }}>
		<div className="customer-support-flex">
			<EmailCustomerSupport />
		</div>
	</div>
);

export default CustomerSupport;
