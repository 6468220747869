/* eslint-disable import/no-cycle */
import _ from 'lodash';
import { set, setIsLoadingLoaded, setErrorMessage, clear } from './actions';
import { ROOT_SLICE } from './constants';
import { initialState } from './initialState';
import {
	selectRootSlice,
	selectIsLoading,
	selectIsLoaded,
	selectErrorMessage
} from './selectors';

const createMaps = (customers = {}) => {
	const listCustomers = Object.keys(customers);
	return listCustomers.reduce(
		(acc, name) => {
			const AVSETTINGS = {
				...(customers[name]['ASSETVIEW®'] || {}),
				...(customers[name].ASSETVIEW || {}),
				...(customers[name].AV || {})
			};
			const hasAvSettings = Object.keys(AVSETTINGS).length;
			if (hasAvSettings) {
				acc.assetviewSettingsMap[name] = AVSETTINGS;
				acc.avMatchTermMap[name] = AVSETTINGS.elasticsearchKeyMatcher;
			}
			return acc;
		},
		{ assetviewSettingsMap: {}, avMatchTermMap: {} }
	);
};

const isDefined = v => v !== undefined && v !== null;

// ************
// PARTS
// ************
const reducerParts = {
	[clear]: state => {
		return {
			...state,
			[ROOT_SLICE]: _.cloneDeep(initialState)
		};
	},

	[set]: (state, { payload: { data } }) => {
		const rootSlice = selectRootSlice(state);
		const { assetviewSettingsMap, avMatchTermMap } = createMaps(data);
		return {
			...state,
			[ROOT_SLICE]: { ...rootSlice, data, assetviewSettingsMap, avMatchTermMap }
		};
	},

	[setIsLoadingLoaded]: (
		state,
		{ payload: { isLoading: newIsLoading, isLoaded: newIsLoaded } }
	) => {
		const rootSlice = selectRootSlice(state);
		const isLoading = selectIsLoading(state);
		const isLoaded = selectIsLoaded(state);

		const CHANGED = {
			isLoading: isDefined(newIsLoading) && newIsLoading !== isLoading,
			isLoaded: isDefined(newIsLoaded) && newIsLoaded !== isLoaded
		};

		if (!CHANGED.isLoaded && !CHANGED.isLoading) {
			return state;
		}

		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
				isLoading: CHANGED.isLoading ? newIsLoading : isLoading,
				isLoaded: CHANGED.isLoaded ? newIsLoaded : isLoaded
			}
		};
	},

	[setErrorMessage]: (
		state,
		{ payload: { errorMessage: newErrorMessage } }
	) => {
		const rootSlice = selectRootSlice(state);
		const errorMessage = selectErrorMessage(state);

		if (newErrorMessage === errorMessage) {
			return state;
		}

		return {
			...state,
			[ROOT_SLICE]: {
				...rootSlice,
				errorMessage: newErrorMessage
			}
		};
	}
};

export default reducerParts;
